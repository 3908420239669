import { AxiosError } from 'axios';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { QBox, QFlex, QSpinner, QStack, useCurrentUser, useToastProvider } from '@qualio/ui-components';

import eventDetailsApi from '../../api/eventDetails.api';
import eventMetadataApi from '../../api/eventMetadata.api';
import eventsApi from '../../api/events.api';
import productsApi from '../../api/products.api';
import qriJson from '../../api/qriJson.api';
import relationsApi from '../../api/relations.api';
import rootCausesApi from '../../api/rootCauses.api';
import tagsApi from '../../api/tags.api';
import usersApi from '../../api/users.api';
import { EventProperties, EventSteps, QualityEventHeader } from '../../components';
import ChangeOwnerModal from '../../components/ChangeOwnerModal/ChangeOwnerModal';
import DigitalSignatureModal from '../../components/DigitalSignatureModal/DigitalSignatureModal';
import DigitalSignatureModalV2 from '../../components/DigitalSignatureModalV2/DigitalSignatureModal';
import UpdateEventPropertiesModal from '../../components/UpdateEventPropertiesModal/UpdateEventPropertiesModal';
import EventEditModal from '../../components/EventModal/EventEditModal';
import EventOpenModal from '../../components/EventModal/EventOpenModal';
import QualityEventIncompleteRequiredFieldsAlert from '../../components/QualityEventAlerts/IncompleteRequiredFieldsAlert';
import UpdateTemplateFieldsAlert from '../../components/QualityEventAlerts/UpdateTemplateFieldsAlert';
import { EventPermissionsProvider } from '../../context';
import { useDocTitle } from '../../hooks';
import { QualityEventCreate, QualityEventDetailsResponse, QualityEventStatus } from '../../types';
import { DigitalSignature, DigitalSignatureInput } from '../../types/digitalSignature';
import {
  RelatedDocumentQriDetails,
  RelatedEventQriDetails,
  RelatedRegistryItemQriDetails,
  SupplierQriDetails,
} from '../../types/relatedData';
import { EventTemplateFields, TemplateFieldDisplayNameMap } from '../../types/eventField';
import { joinReadable } from '../../utils/arrayUtils';
import { filterTags } from '../../utils/eventUtils';
import ExportUtil from '../../utils/exportUtils';
import { getUserTZ } from '../../utils/userUtils';
import EscalateEventModal from '../../components/EscalateEventModal/EscalateEventModal';
import { extractRelatedQrisFromQueryEventData, qriDetailsAsSelectData } from '../../utils/qriUtils';
import { useFlags } from '../../external/LaunchDarklyAdapter';

const QualityEventDetails: React.FC = () => {
  const { companyId, userId } = useCurrentUser();
  const { eventId } = useParams();
  const { showToast } = useToastProvider();
  const exportUtil = new ExportUtil(showToast);

  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState<boolean>(false);
  const [isEscalateEventModalOpen, setIsEscalateEventModalOpen] = useState<boolean>(false);
  const [isChangeOwnerModalOpen, setIsChangeOwnerModalOpen] = useState<boolean>(false);
  const [isReopenModalOpen, setIsReopenModalOpen] = useState<boolean>(false);
  const [isOpenModalOpen, setIsOpenModalOpen] = useState<boolean>(false);
  const [isCloseEventModalOpen, setIsCloseEventModalOpen] = useState<boolean>(false);
  const [isUpdateEventPropertiesModalOpen, setIsUpdateEventPropertiesModalOpen] = useState<boolean>(false);
  const [eventData, setEventData] = useState<QualityEventDetailsResponse | undefined>();
  const [suppliersData, setSuppliersData] = useState<SupplierQriDetails[]>([]);
  const [relatedDocumentsData, setRelatedDocumentsData] = useState<RelatedDocumentQriDetails[]>([]);
  const [relatedEventsData, setRelatedEventsData] = useState<RelatedEventQriDetails[]>([]);
  const [relatedProductData, setRelatedProductData] = useState<RelatedRegistryItemQriDetails | undefined | null>(
    undefined,
  );
  const [relatedRootCauseData, setRelatedRootCauseData] = useState<RelatedRegistryItemQriDetails | undefined | null>(
    undefined,
  );
  const qeOnepassTesting = useFlags('qeOnepassTesting');
  const SignatureModal = qeOnepassTesting ? DigitalSignatureModalV2 : DigitalSignatureModal;

  const {
    data: queryEventData,
    isFetching,
    refetch,
  } = useQuery(
    ['eventDetails', companyId, eventId],
    () => eventDetailsApi.getEventDetails(companyId, Number(eventId)),
    {
      onError: (error: AxiosError) => {
        if (error?.response?.status === 404) {
          window.location.href = `/_rpm?reqUrl=${window.location.pathname}`;
        } else {
          return <QBox>Failed to fetch event details</QBox>;
        }
      },
    },
  );

  const getQriData = async (qrisToFetch: string[]) => {
    const qriDetails = await qriJson.batchGet(qrisToFetch);

    if (qriDetails) {
      setSuppliersData(qriDetails.suppliers);
      setRelatedDocumentsData(qriDetails.documents);
      setRelatedEventsData(qriDetails.events);
      setRelatedProductData(qriDetails.product);
      setRelatedRootCauseData(qriDetails.rootcause);
    }
  };

  useEffect(() => {
    if (isFetching) {
      return;
    }
    setEventData(queryEventData);
    const qris = extractRelatedQrisFromQueryEventData(queryEventData);

    if (qris.length === 0) {
      setSuppliersData([]);
      setRelatedDocumentsData([]);
      setRelatedEventsData([]);
      setRelatedProductData(undefined);
      setRelatedRootCauseData(undefined);
      return;
    }
    getQriData(qris).catch(console.error);
  }, [isFetching, queryEventData]);

  const { data: relationData, refetch: refetchRelations } = useQuery(['relations', companyId, eventId], () =>
    relationsApi.get(companyId, Number(eventId)),
  );

  const { data: tagsData } = useQuery(['tagsList', companyId], () => tagsApi.getAllTags(companyId));
  const { data: productsData } = useQuery(['productsList', companyId], () => productsApi.getAllProducts(companyId, {}));
  const { data: rootCauseData } = useQuery(['rootCausesList', companyId], () =>
    rootCausesApi.getAllRootCauses(companyId, {}),
  );
  const { data: usersData } = useQuery(['usersList', companyId], () =>
    usersApi.getAllUsers(companyId, { status: 'accepted' }),
  );
  const { data: eventsMetadata } = useQuery(['eventMetadata', companyId], () =>
    eventMetadataApi.getMetadata(companyId),
  );

  const eventDetails = eventData?.issue;

  useEffect(() => {
    if (eventId) {
      refetch().then();
      refetchRelations().then();
    }
  }, [eventId, refetch, refetchRelations, eventDetails]);

  useDocTitle(eventDetails ? `${eventDetails.code} ${eventDetails.title} - Qualio` : 'Qualio');

  const handleEventEditSave = useCallback(
    async (payload: QualityEventCreate) => {
      if (!eventId) {
        return;
      }
      await eventsApi.update(companyId, eventId, payload);
      refetch().then();
      refetchRelations().then();
    },
    [companyId, eventId, refetch, refetchRelations],
  );

  const handleEscalateEvent = useCallback(
    async (payload: QualityEventCreate) => {
      const newEvent = await eventsApi.create(companyId, payload);
      refetch().then();
      refetchRelations().then();

      return newEvent;
    },
    [companyId, refetch, refetchRelations],
  );

  const handleCloseEvent = useCallback(
    async (payload: DigitalSignature) => {
      if (!eventId) {
        return;
      }
      await eventDetailsApi.closeEvent(companyId, eventId, payload);
      refetch().then();
      refetchRelations().then();
    },
    [companyId, eventId, refetch, refetchRelations],
  );

  const handleReopen = useCallback(
    async (payload: DigitalSignature) => {
      if (!eventId) {
        return;
      }
      await eventDetailsApi.reopenEvent(companyId, eventId, payload);
      refetch().then();
      refetchRelations().then();
    },
    [companyId, eventId, refetch, refetchRelations],
  );

  const handleOpen = useCallback(
    async (payload: QualityEventCreate) => {
      if (!eventId) {
        return;
      }
      const openEventData = {
        ...payload,
        ...{ status: 'open' },
      };
      await eventsApi.update(companyId, eventId, openEventData);
      refetch().then();
      refetchRelations().then();
    },
    [companyId, eventId, refetch, refetchRelations],
  );

  const closeEventModalInput: DigitalSignatureInput = {
    headingText: 'Close Event',
    subText: 'This action will cancel all event steps and mark the event as closed.',
    submitButtonText: 'Close event',
    successToastHeader: 'Event Closed',
    successToastDescription: `Event ${eventDetails?.code} Successfully closed`,
  };

  const reopenEventModalInput: DigitalSignatureInput = {
    headingText: 'Reopen Event',
    subText: 'Are you sure that you want to reopen this issue?',
    submitButtonText: 'Reopen event',
    successToastHeader: 'Event Reopened',
    successToastDescription: `Event ${eventDetails?.code} Successfully reopened`,
  };

  const handleEditEventClicked = useMemo(() => () => setIsEditEventModalOpen(true), []);

  const handleCloseEventApiEror = useMemo(
    () => (err: any) => {
      const fields = err?.response?.data?.detail;
      if (err?.response?.status === 406 && Array.isArray(fields)) {
        const fieldsMessage = joinReadable(fields.map((field) => <b key={`${field}`}>{field}</b>));
        showToast({
          title: 'Missing information',
          description: (
            <span>
              {fieldsMessage} field{fields.length > 1 ? 's' : ''} must be completed in order to close event.
              <button style={{ color: '#3D4CF2' }} onClick={handleEditEventClicked}>
                &nbsp;Edit event
              </button>
            </span>
          ),
          status: 'error',
        });
        return true;
      }
      return false;
    },
    [handleEditEventClicked, showToast],
  );
  const eventSteps = eventData?.steps;

  const allButLastStepIsComplete =
    eventSteps?.filter(({ status }) => {
      return status !== 'completed';
    }).length === 1;

  const incompleteRequiredFields = useMemo(() => {
    if (!allButLastStepIsComplete || !eventDetails || !eventDetails.template_fields) {
      return [];
    }
    const fieldIsIncomplete = (field?: number | string | string[] | null) => {
      if (!(field ?? false)) {
        return true;
      }
      if (Array.isArray(field) && field.length === 0) {
        return true;
      }
      return false;
    };
    const requiredFields = eventDetails.template_fields.filter(({ mandatory }) => mandatory);
    const incompleteRequiredFieldNames: string[] = requiredFields
      .filter(({ attribute_name }) => {
        switch (attribute_name) {
          case 'root_cause':
            return fieldIsIncomplete(eventDetails.rootcause_id);
          case 'related_to':
            console.error('Related to should not be required');
            return false;
          case 'supplier':
            return fieldIsIncomplete(eventDetails?.suppliers);
          case 'risk':
            return (
              fieldIsIncomplete(eventDetails.risk_id) ||
              fieldIsIncomplete(eventDetails.severity_id) ||
              fieldIsIncomplete(eventDetails.probability_id)
            );
          default:
            return fieldIsIncomplete(eventDetails[`${attribute_name}_id`]);
        }
      })
      .map(({ attribute_name }) => attribute_name);
    if (incompleteRequiredFieldNames.includes('risk')) {
      eventDetails?.severity_id ?? incompleteRequiredFieldNames.push(TemplateFieldDisplayNameMap['severity']);
      eventDetails?.probability_id ?? incompleteRequiredFieldNames.push(TemplateFieldDisplayNameMap['probability']);
      incompleteRequiredFieldNames.splice(incompleteRequiredFieldNames.indexOf('risk'), 1);
    }
    const incompleteRequiredFieldNamesDisplay = incompleteRequiredFieldNames.map((field) => {
      return field in TemplateFieldDisplayNameMap
        ? TemplateFieldDisplayNameMap[field as keyof typeof TemplateFieldDisplayNameMap]
        : field[0]?.toUpperCase() + field?.slice(1);
    });
    return incompleteRequiredFieldNamesDisplay;
  }, [eventDetails, allButLastStepIsComplete]);

  if (isFetching || !eventData || !eventDetails || eventSteps === undefined) {
    return (
      <QFlex height="100vh" justify="center" align="center">
        <QStack direction="column" align="center">
          <QSpinner marginTop="-48" />
        </QStack>
      </QFlex>
    );
  }
  const suppliersTemplateField = eventDetails?.template_fields?.filter(
    ({ attribute_name }) => attribute_name === 'supplier',
  )[0];
  const suppliersConfiguration = {
    enabled: !!suppliersTemplateField,
    required: suppliersTemplateField?.mandatory ?? false,
  };

  const filteredTagsData = filterTags(userId, usersData ?? [], tagsData ?? []);
  const userTZ = getUserTZ(userId, usersData ?? []);

  const updateEventTemplateFields = (templateFields: EventTemplateFields) => {
    setEventData({ ...eventData, issue: { ...eventDetails, template_fields: templateFields } });
  };

  const shouldDisplaySyncAlert = () => {
    return eventId && eventDetails.status !== QualityEventStatus.enum.closed;
  };
  const shouldDisplayRequiredFieldsAlert = () => allButLastStepIsComplete && incompleteRequiredFields.length > 0;

  return (
    <EventPermissionsProvider userId={userId} event={eventDetails}>
      <QualityEventHeader
        code={eventDetails.code}
        title={eventDetails.title}
        status={eventDetails.status}
        due={eventDetails.due_at}
        updated_at={eventDetails.updated_at}
        description={eventDetails.description}
        onEditEventClicked={handleEditEventClicked}
        onChangeOwnerClicked={() => setIsChangeOwnerModalOpen(true)}
        onEscalateEventClicked={() => setIsEscalateEventModalOpen(true)}
        onReopenClicked={() => setIsReopenModalOpen(true)}
        onOpenClicked={() => setIsOpenModalOpen(true)}
        onCloseEventClicked={() => setIsCloseEventModalOpen(true)}
        onUpdateEventPropertiesClicked={() => setIsUpdateEventPropertiesModalOpen(true)}
        exportUtil={exportUtil}
        eventId={eventId as unknown as number}
        companyId={companyId}
        userTZ={userTZ}
        templateName={eventDetails.workflow}
      />
      {shouldDisplayRequiredFieldsAlert() && (
        <QualityEventIncompleteRequiredFieldsAlert
          alertButtonAction={handleEditEventClicked}
          incompleteRequiredFields={incompleteRequiredFields}
        />
      )}
      {shouldDisplaySyncAlert() && (
        <UpdateTemplateFieldsAlert
          ownerId={eventData.issue.owner_id}
          updateEventTemplateFields={updateEventTemplateFields}
          eventTemplateId={eventData.issue.workflow_id}
          eventId={eventId!} // linter does not recognize we ensure this is not null
          existingTemplateFields={eventData.issue.template_fields}
        />
      )}
      <QFlex justifyContent={'space-between'}>
        <EventSteps steps={eventSteps} users={usersData ?? []} refetch={refetch} userTZ={userTZ} />
        <QBox maxWidth={'20%'}>
          <EventProperties
            event={eventData}
            relations={relationData}
            relatedSuppliers={Object.values(suppliersData)}
            relatedEvents={Object.values(relatedEventsData)}
            relatedDocuments={Object.values(relatedDocumentsData)}
            relatedProduct={relatedProductData}
            relatedRootCause={relatedRootCauseData}
            tags={tagsData}
          />
        </QBox>
      </QFlex>
      <EventEditModal
        isOpen={isEditEventModalOpen}
        event={eventDetails}
        related={relationData ?? []}
        setIsOpen={setIsEditEventModalOpen}
        suppliers={qriDetailsAsSelectData(suppliersData)}
        relatedData={{
          relatedDocuments: qriDetailsAsSelectData(relatedDocumentsData),
          relatedEvents: qriDetailsAsSelectData(relatedEventsData),
        }}
        selectsData={{
          eventTags: filteredTagsData ?? [],
          probabilities: eventsMetadata?.probabilities ?? [],
          severities: eventsMetadata?.severities ?? [],
          risks: eventsMetadata?.risks ?? [],
          users: usersData ?? [],
          products: productsData ?? [],
          rootCauses: rootCauseData ?? [],
        }}
        onSave={handleEventEditSave}
      />
      <ChangeOwnerModal
        isOpen={isChangeOwnerModalOpen}
        event={eventDetails}
        setIsOpen={setIsChangeOwnerModalOpen}
        selectsData={{
          users: usersData ?? [],
        }}
        onSave={handleEventEditSave}
      />
      <EscalateEventModal
        isOpen={isEscalateEventModalOpen}
        setIsOpen={setIsEscalateEventModalOpen}
        onSave={handleEscalateEvent}
        event={eventDetails}
        selectsData={{
          eventTags: filteredTagsData ?? [],
          probabilities: eventsMetadata?.probabilities ?? [],
          severities: eventsMetadata?.severities ?? [],
          risks: eventsMetadata?.risks ?? [],
          users: usersData ?? [],
          products: productsData ?? [],
          rootCauses: rootCauseData ?? [],
        }}
      />
      <UpdateEventPropertiesModal
        isOpen={isUpdateEventPropertiesModalOpen}
        event={eventDetails}
        setIsOpen={setIsUpdateEventPropertiesModalOpen}
        suppliersConfiguration={suppliersConfiguration}
        selectsData={{
          eventTags: filteredTagsData ?? [],
          relatedDocuments: qriDetailsAsSelectData(relatedDocumentsData),
          relatedEvents: qriDetailsAsSelectData(relatedEventsData),
          relatedSuppliers: qriDetailsAsSelectData(suppliersData),
          sourceEvents:
            eventDetails?.escalated_from?.map(({ title, id, code }) => ({
              label: `${code} ${title}`,
              title,
              value: String(id),
            })) ?? [],
          escalatedTo:
            eventDetails?.escalated_to?.map(({ title, id, code }) => ({
              label: `${code} ${title}`,
              title,
              value: String(id),
            })) ?? [],
        }}
        onSave={handleEventEditSave}
      />
      <SignatureModal
        isOpen={isCloseEventModalOpen}
        setIsOpen={setIsCloseEventModalOpen}
        onSave={handleCloseEvent}
        inputTexts={closeEventModalInput}
        isCommentRequired={true}
        onSubmitError={handleCloseEventApiEror}
      />
      <SignatureModal
        isOpen={isReopenModalOpen}
        setIsOpen={setIsReopenModalOpen}
        onSave={handleReopen}
        inputTexts={reopenEventModalInput}
        isCommentRequired={false}
      />
      <EventOpenModal
        isOpen={isOpenModalOpen}
        event={eventDetails}
        related={relationData ?? []}
        setIsOpen={setIsOpenModalOpen}
        suppliers={qriDetailsAsSelectData(suppliersData)}
        selectsData={{
          eventTags: filteredTagsData ?? [],
          probabilities: eventsMetadata?.probabilities ?? [],
          severities: eventsMetadata?.severities ?? [],
          risks: eventsMetadata?.risks ?? [],
          users: usersData ?? [],
          products: productsData ?? [],
          rootCauses: rootCauseData ?? [],
        }}
        onSave={handleOpen}
      />
    </EventPermissionsProvider>
  );
};

export default QualityEventDetails;
