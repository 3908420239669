import * as z from 'zod';
import { RegistryCategoryMatrixID } from './registry';

const QriDetailsBaseSchema = z.object({
  link: z.string(),
  domain: z.string(),
  qri: z.string(),
});

export const SupplierQriDetailsSchema = QriDetailsBaseSchema.merge(
  z.object({
    id: z.string(),
    name: z.string(),
    type: z.string().optional(),
    status: z.string(),
    version: z.number(),
    domain: z.literal('suppliers'),
  }),
);
export type SupplierQriDetails = z.infer<typeof SupplierQriDetailsSchema>;

export const RelatedDocumentQriDetailsSchema = QriDetailsBaseSchema.merge(
  z.object({
    domain: z.literal('documents'),
    title: z.string(),
    documentId: z.string(),
    code: z.string(),
  }),
);
export type RelatedDocumentQriDetails = z.infer<typeof RelatedDocumentQriDetailsSchema>;

export const RelatedEventQriDetailsSchema = QriDetailsBaseSchema.merge(
  z.object({
    domain: z.literal('quality-events'),
    title: z.string(),
    id: z.union([z.string(), z.number().transform((val) => val?.toString())]),
    code: z.string(),
  }),
);
export type RelatedEventQriDetails = z.infer<typeof RelatedEventQriDetailsSchema>;

export const RelatedRegistryItemQriDetailsSchema = QriDetailsBaseSchema.merge(
  z.object({
    domain: z.literal('registry'),
    name: z.string(),
    registry_category_id: z.nativeEnum(RegistryCategoryMatrixID),
  }),
);
export type RelatedRegistryItemQriDetails = z.infer<typeof RelatedRegistryItemQriDetailsSchema>;

export const QriDetailsSchema = z.discriminatedUnion('domain', [
  SupplierQriDetailsSchema,
  RelatedEventQriDetailsSchema,
  RelatedDocumentQriDetailsSchema,
  RelatedRegistryItemQriDetailsSchema,
]);

export type QriDetails = z.infer<typeof QriDetailsSchema>;

export const BatchQriDetailsSchema = z.record(z.string(), z.any()).transform((item) => {
  const suppliers = [];
  const events = [];
  const documents = [];
  let product = undefined;
  let rootcause = undefined;
  const unknown = [];
  for (const k in item) {
    const result = QriDetailsSchema.safeParse({ ...item[k], qri: k });
    if (result.success) {
      switch (result.data.domain) {
        case 'suppliers':
          suppliers.push(result.data);
          break;
        case 'quality-events':
          events.push(result.data);
          break;
        case 'documents':
          documents.push(result.data);
          break;
        case 'registry':
          if (result.data.registry_category_id === RegistryCategoryMatrixID.PRODUCT_MATRIX_ID) {
            product = result.data;
          }
          if (result.data.registry_category_id === RegistryCategoryMatrixID.ROOT_CAUSE_MATRIX_ID) {
            rootcause = result.data;
          }
          break;
        default:
          unknown.push(result.data);
      }
    } else {
      unknown.push({ ...item[k], qri: k });
    }
  }

  return ParsedBatchQriDetails.parse({ suppliers, events, documents, product, rootcause, unknown });
});

export const ParsedBatchQriDetails = z.object({
  suppliers: z.array(SupplierQriDetailsSchema),
  events: z.array(RelatedEventQriDetailsSchema),
  documents: z.array(RelatedDocumentQriDetailsSchema),
  product: RelatedRegistryItemQriDetailsSchema.nullish(),
  rootcause: RelatedRegistryItemQriDetailsSchema.nullish(),
  unknown: z.array(QriDetailsBaseSchema),
});
