import React from 'react';
import { useCurrentUser, QSpinner } from '@qualio/ui-components';
import { useParams } from 'react-router-dom';
import { EventTemplateFormValues } from '../../types';
import EventTemplateForm from './components/EventTemplateForm';
import { useGetUserTimezone, useTemplateQuery } from './hooks';
import { useDocTitle } from '../../hooks';
import { usePossibleEventOwners } from '../../hooks/usePossibleEventOwners';
import { DefaultFormValues } from './formHelpers';

type EventTemplateProps = {
  viewType: 'new' | 'edit';
};

const EventTemplate: React.FC<EventTemplateProps> = ({ viewType }) => {
  const { companyId, userId } = useCurrentUser();
  const { eventTemplateId } = useParams();
  const isEditView = viewType === 'edit';
  const templateQuery = useTemplateQuery(companyId, eventTemplateId, isEditView);
  const timeZoneQuery = useGetUserTimezone(userId, companyId);
  const possibleOwnersQuery = usePossibleEventOwners(companyId);

  useDocTitle(isEditView ? `${templateQuery.data?.name} - Event templates - Qualio` : 'Event templates - Qualio');

  // When in edit mode we need to wait for template to load after mount, otherwise we can render immediately
  const templateIsLoading = isEditView ? templateQuery.isLoading || !templateQuery.isFetchedAfterMount : false;

  if (templateIsLoading || timeZoneQuery.isLoading || timeZoneQuery.isIdle || !possibleOwnersQuery.data) {
    return <QSpinner />;
  }

  if (templateQuery.isError || timeZoneQuery.isError) {
    throw new Error('Could not find event template or user timezone');
  }

  // templateQuery.data should always exist here if we are in edit view and aren't loading, but TS doesn't know that
  const data: EventTemplateFormValues = isEditView && templateQuery.data ? templateQuery.data : DefaultFormValues;

  return (
    <EventTemplateForm
      initialValues={data}
      possibleOwners={possibleOwnersQuery.data}
      companyId={companyId}
      templateId={eventTemplateId ? Number(eventTemplateId) : undefined}
    />
  );
};

export default EventTemplate;
