import React, { useMemo } from 'react';
import { QFormControl, QInput, QSelect, QStack } from '@qualio/ui-components';
import { Controller, FormProvider, UseFormReturn } from 'react-hook-form';
import { EventTemplateFormValues, trimAndConvertToNumber, User } from '../../types';
import { useFlags } from '../../external/LaunchDarklyAdapter';
import * as DisplayStrings from '../../displayStrings';

type Props = {
  formMethods: UseFormReturn<EventTemplateFormValues>;
  possibleOwners: readonly User[];
  isCreateView: boolean;
};

const TemplateDetailsForm: React.FC<Props> = ({ formMethods, isCreateView, possibleOwners }) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = formMethods;
  const qeDefaultOwnersEnabled = useFlags('qeDefaultOwners');
  const defaultOwnerOptions = useMemo(
    () => possibleOwners.map((owner) => ({ value: `${owner.id}`, label: owner.full_name })),
    [possibleOwners],
  );

  return (
    <FormProvider {...formMethods}>
      <QStack spacing={4}>
        <QFormControl label="Name" error={errors.name && errors.name.message} isInvalid={!!errors.name} isRequired>
          <Controller
            control={control}
            name="name"
            render={({ field: { ref, ...field } }) => (
              <QInput
                {...field}
                onKeyUp={async () => {
                  await trigger('name');
                }}
                data-cy="Name"
              />
            )}
          />
        </QFormControl>

        <QFormControl
          label="Prefix"
          error={errors.prefix && errors.prefix.message}
          isInvalid={!!errors.prefix}
          isReadOnly={!isCreateView}
          helper="A prefix will be automatically added to every event created."
          isRequired={isCreateView}
        >
          <Controller
            control={control}
            name="prefix"
            render={({ field: { ref, ...field } }) => (
              <QInput
                {...field}
                onKeyUp={async () => {
                  await trigger('prefix');
                }}
                data-cy="Prefix"
              />
            )}
          />
        </QFormControl>

        {qeDefaultOwnersEnabled ? (
          <Controller
            control={control}
            name="default_owner_id"
            render={({ field: { onChange, ref, ...field }, fieldState }) => {
              return (
                <QFormControl
                  label={DisplayStrings.DefaultOwner}
                  isInvalid={fieldState.invalid}
                  error={fieldState.error?.message}
                  helper={DisplayStrings.DefaultOwnerHelper}
                >
                  <QSelect
                    {...field}
                    data-cy="DefaultOwner"
                    options={defaultOwnerOptions}
                    onChange={(changeObject) => {
                      onChange(changeObject ? changeObject.value : '');
                    }}
                  />
                </QFormControl>
              );
            }}
          />
        ) : null}

        <QFormControl
          label="Time limit (days)"
          error={errors.default_time_limit && errors.default_time_limit.message}
          isInvalid={!!errors.default_time_limit}
          helper="Each event must be resolved in given number of days. This is a default value and may be changed during event creation."
          isRequired
        >
          <Controller
            control={control}
            name="default_time_limit"
            render={({ field: { ref, ...field } }) => (
              <QInput
                {...field}
                // Needs to be here so that react hook form can properly tell if the field is dirty
                onChange={(e) => {
                  const value = e.target.value;

                  field.onChange(trimAndConvertToNumber(value));
                }}
                onKeyUp={async () => {
                  await trigger('default_time_limit');
                }}
                type="number"
                data-cy="DefaultTimeLimit"
              />
            )}
          />
        </QFormControl>

        <QFormControl
          label="Validation step time delay (days)"
          error={errors.start_time_constraint && errors.start_time_constraint.message}
          isInvalid={!!errors.start_time_constraint}
          helper="Determines how many days must pass before being able to complete approval of final step."
          isRequired
        >
          <Controller
            control={control}
            name="start_time_constraint"
            render={({ field: { ref, ...field } }) => (
              <QInput
                {...field}
                // Needs to be here so that react hook form can properly tell if the field is dirty
                onChange={(e) => {
                  const value = e.target.value;

                  field.onChange(trimAndConvertToNumber(value));
                }}
                onKeyUp={async () => {
                  await trigger('start_time_constraint');
                }}
                type="number"
                data-cy="StartTimeConstraint"
              />
            )}
          />
        </QFormControl>
      </QStack>
    </FormProvider>
  );
};

export default TemplateDetailsForm;
