import { useQuery } from 'react-query';
import usersApi from '../api/users.api';
import { filterByPermission } from '../utils/userUtils';

export const BaseQueryKey = 'userList';

export const usePossibleEventOwners = (companyId: number) => {
  return useQuery([BaseQueryKey, companyId], () => usersApi.getAllUsers(companyId, { status: 'accepted' }), {
    select: (data) => filterByPermission(data, 'can_work_on_issue'),
  });
};
