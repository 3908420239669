import React, { useCallback } from 'react';
import {
  QModal,
  QStack,
  QText,
  useToastProvider,
  QModalHeader,
  QModalBody,
  QModalActions,
  QButton,
  QCloseButton,
} from '@qualio/ui-components';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import {
  DigitalSignatureInput,
  DigitalSignatureV2,
  DigitalSignatureV2CommentRequired,
  DigitalSignatureV2CommentRequiredSchema,
  DigitalSignatureV2Schema,
} from '../../types/digitalSignature';
import DigitalSignatureForm from './components/DigitalSignatureForm';
import { extractMessageFromError } from '../../utils/errorUtils';

type ModalProps = {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: (payload: DigitalSignatureV2) => Promise<void>;
  inputTexts: DigitalSignatureInput;
  isCommentRequired: boolean;
  onSubmitError?: (err: unknown) => boolean;
};

/**
 * NOTE: This is a temporary copy of DigitalSignatureModal.tsx just for the duration of of fixing
 * issus with 1password. Once the issue is fixed, this file should be either deleted or replace the original file.
 */
const DigitalSignatureModal = ({
  isOpen,
  setIsOpen,
  onSave,
  inputTexts,
  isCommentRequired,
  onSubmitError,
}: ModalProps) => {
  const { showToast } = useToastProvider();

  const formMethods = useForm<DigitalSignatureV2 | DigitalSignatureV2CommentRequired>({
    mode: 'onSubmit',
    resolver: zodResolver(isCommentRequired ? DigitalSignatureV2CommentRequiredSchema : DigitalSignatureV2Schema),
    defaultValues: {
      email: '',
      password: '',
      comment: '',
    },
  });

  const {
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
    control,
  } = formMethods;
  const formHasErrors = !!Object.keys(errors).length;

  const handleClose = () => {
    setIsOpen(false);
    reset();
  };

  const onSubmit = useCallback(
    async (data: DigitalSignatureV2) => {
      try {
        await onSave(data);
        setIsOpen(false);
        showToast({
          title: inputTexts.successToastHeader,
          description: inputTexts.successToastDescription,
          status: 'success',
        });
        reset();
      } catch (err: any) {
        if (!onSubmitError?.(err)) {
          let errorMessage = extractMessageFromError(err);
          if (errorMessage === 'Invalid email address; Invalid email or password') {
            // explicitly indicating email is not correct is a security smell, so we replace the message in this case
            errorMessage = 'Invalid credentials';
          }
          showToast({
            title: 'Failed',
            description: errorMessage,
            status: 'error',
          });
        }
      }
    },
    [
      onSave,
      setIsOpen,
      showToast,
      inputTexts.successToastHeader,
      inputTexts.successToastDescription,
      reset,
      onSubmitError,
    ],
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <QModal isOpen={isOpen} onClose={handleClose} size="xl">
          <QModalHeader>
            <QText>{inputTexts.headingText}</QText>
            <QCloseButton onClick={handleClose}></QCloseButton>
          </QModalHeader>
          <QModalBody pt={'2px'}>
            <QStack direction="column" color="gray.700" spacing={4}>
              <QText color="gray.800">{inputTexts.subText}</QText>
              <DigitalSignatureForm control={control} isCommentRequired={isCommentRequired} />
            </QStack>
          </QModalBody>
          <QModalActions>
            <QButton onClick={handleClose} variant="outline">
              Cancel
            </QButton>
            <QButton onClick={() => handleSubmit(onSubmit)()} isDisabled={formHasErrors || isSubmitting}>
              {inputTexts.submitButtonText}
            </QButton>
          </QModalActions>
        </QModal>
      </form>
    </FormProvider>
  );
};

export default DigitalSignatureModal;
