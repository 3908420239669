import React, { useCallback } from 'react';
import {
  QBox,
  QStack,
  QHeading,
  QText,
  QCloseButton,
  QSpacer,
  QDivider,
  QCenter,
  QButton,
  CurrentUserContextType,
} from '@qualio/ui-components';
import { TemplateDetailsForm, TemplateFieldsList, TemplateStepsList } from '../../../components';
import { EventTemplateFormSchema, EventTemplateFormValues, User } from '../../../types';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTemplateMutation } from '../hooks';
import StepInput from './StepInput';
import StepRenameModal from '../../../components/TemplateStepsList/components/StepRenameModal';
import ValidationStepTypeModal from '../../../components/TemplateStepsList/components/ValidationStepTypeModal';
import { StepInputState } from '../../../types/eventTemplateStepInputState';
import * as DisplayStrings from '../../../displayStrings';
import { ArrayElement } from '../../../utils/typeUtils';
import { getBlankStep } from '../../../utils/eventTemplateUtils';
import { elementAtIndex } from '../../../utils/arrayUtils';

type EventTemplateFormProps = {
  initialValues: EventTemplateFormValues;
  possibleOwners: readonly User[];
  companyId: CurrentUserContextType['companyId'];
  templateId?: number;
};

const EventTemplateForm = (props: EventTemplateFormProps) => {
  const { initialValues, companyId, templateId, possibleOwners } = props;
  const isCreateView = !templateId;
  const [stepInputState, setStepInputState] = React.useState<StepInputState>({ type: 'empty' });
  const navigate = useNavigate();

  const formMethods = useForm<EventTemplateFormValues>({
    mode: 'onSubmit',
    resolver: zodResolver(EventTemplateFormSchema),
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    formState: { isDirty, isValid },
    reset,
    trigger,
  } = formMethods;

  const mutationArgs = isCreateView
    ? ({ mode: 'create', companyId } as const)
    : ({ mode: 'update', companyId, templateId, originalTemplate: initialValues } as const);
  const { mutate: mutateTemplate, isLoading: templateIsMutating } = useTemplateMutation(mutationArgs);

  const onSubmit = useCallback(
    (payload: EventTemplateFormValues) => {
      mutateTemplate(payload, {
        onSuccess: (data) => reset(data),
      });
    },
    [mutateTemplate, reset],
  );

  const navigateTemplateList = () => navigate('/templates');

  const setTemplateFields = useCallback(
    (fields: EventTemplateFormValues['fields']) => {
      setValue('fields', fields, { shouldDirty: true });
      trigger('fields');
    },
    [setValue, trigger],
  );

  const setTemplateSteps = useCallback(
    (steps: EventTemplateFormValues['steps']) => {
      const processedSteps = steps.map((step, index) => {
        return {
          ...step,
          order: index + 1,
        };
      });

      setValue('steps', processedSteps, { shouldDirty: true });
      trigger('steps');
    },
    [setValue, trigger],
  );

  const setStepToRename = useCallback(
    (stepIndex: number) => {
      const steps = getValues('steps');
      const step = elementAtIndex(steps, stepIndex);

      setStepInputState({ type: 'rename', step, stepIndex });
    },
    [setStepInputState, getValues],
  );

  const setValidationTypeChange = useCallback(
    (stepIndex: number) => {
      const steps = getValues('steps');
      const step = elementAtIndex(steps, stepIndex);

      setStepInputState({ type: 'validation-type-change', step, stepIndex });
    },
    [setStepInputState, getValues],
  );

  const closeStepInput = useCallback(() => setStepInputState({ type: 'empty' }), [setStepInputState]);

  return (
    <>
      <QBox data-cy={'event-template-screen'}>
        <QStack direction="row">
          <QBox>
            <QHeading size="lg">{isCreateView ? 'Create' : 'Edit'} event template</QHeading>
            {!isCreateView && <QText color="gray.500">{getValues('name')}</QText>}
          </QBox>
          <QSpacer />
          <QCloseButton onClick={navigateTemplateList} />
        </QStack>
        <QDivider mt={6} mb={6} />
        <QCenter>
          <QStack direction="column" spacing={8} width="100%">
            <QHeading size="md">Event properties</QHeading>
            <TemplateDetailsForm
              formMethods={formMethods}
              isCreateView={isCreateView}
              possibleOwners={possibleOwners}
            />
            <QDivider />
            <TemplateFieldsList templateFields={getValues('fields')} setTemplateFields={setTemplateFields} />
            <TemplateStepsList
              companyId={companyId}
              templateSteps={getValues('steps')}
              setTemplateSteps={setTemplateSteps}
              disableEdit={isDirty || isCreateView}
              renameTemplateStep={setStepToRename}
              changeValidationStepType={setValidationTypeChange}
            />
            {stepInputState.type === 'empty' ? (
              <QBox>
                <QButton
                  data-cy={'add-template-step'}
                  variant="outline"
                  leftIcon="Plus"
                  onClick={() => setStepInputState({ type: 'create' })}
                >
                  Add step
                </QButton>
              </QBox>
            ) : null}
            {stepInputState.type === 'create' ? (
              <StepInput
                title="Add step"
                submitText="Add step"
                submitLabel="add-step-confirm"
                submit={(stepName, stepType) => {
                  const steps = getValues('steps').slice();
                  const newStep = {
                    ...getBlankStep(),
                    label: stepName,
                    type: stepType,
                  } as ArrayElement<EventTemplateFormValues['steps']>;
                  const validationStep = steps.pop();
                  const newStepArray = [...steps, newStep];

                  if (validationStep) {
                    newStepArray.push(validationStep);
                  }

                  setTemplateSteps(newStepArray);
                  closeStepInput();
                }}
                checkDuplicated={(stepName) => {
                  const stepNames = new Set(getValues('steps').map((step) => step.label));

                  return stepNames.has(stepName.trim());
                }}
                closeInput={closeStepInput}
              />
            ) : null}
          </QStack>
        </QCenter>
        <QDivider mt={6} mb={6} />
        <QStack spacing={4} direction="row-reverse">
          <QButton
            onClick={handleSubmit(onSubmit)}
            isDisabled={!isDirty || !isValid}
            data-cy="EventTemplateSaveButton"
            isLoading={templateIsMutating}
          >
            {DisplayStrings.SaveChanges}
          </QButton>
          <QButton variant="link" onClick={navigateTemplateList} data-cy="cancelButton">
            {DisplayStrings.Cancel}
          </QButton>
        </QStack>
      </QBox>
      {stepInputState.type === 'rename' ? (
        <StepRenameModal
          stepInputState={stepInputState}
          closeInput={() => setStepInputState({ type: 'empty' })}
          currentName={stepInputState.step.label}
          submit={(stepName) => {
            const steps = getValues('steps').slice();
            const step = elementAtIndex(steps, stepInputState.stepIndex);
            step.label = stepName;

            setTemplateSteps(steps);
            setStepInputState({ type: 'empty' });
          }}
          checkDuplicated={(stepName) => {
            const stepNames = new Set(
              getValues('steps')
                .filter((step) => step.label !== stepInputState.step.label)
                .map((step) => step.label),
            );

            return stepNames.has(stepName.trim());
          }}
        />
      ) : null}
      {stepInputState.type === 'validation-type-change' ? (
        <ValidationStepTypeModal
          stepInputState={stepInputState}
          currentType={stepInputState.step.type}
          closeInput={() => setStepInputState({ type: 'empty' })}
          submit={(stepType: 'document' | 'form') => {
            const steps = getValues('steps').slice();
            const step = elementAtIndex(steps, stepInputState.stepIndex);
            step.type = stepType;
            setTemplateSteps(steps);
            setStepInputState({ type: 'empty' });
          }}
        />
      ) : null}
    </>
  );
};

export default EventTemplateForm;
