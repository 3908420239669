import React, { useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { QButton, useCurrentUser, Pagination, Sorting, DataProvider } from '@qualio/ui-components';
import { EventStep, TaskCompleteRequest, UserTask } from '../../../types';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import StepBody from './StepBody';
import TaskStepMetadata from './TaskStepMetadata';
import EmptyStep from './EmptyStep';
import StepTasksListV2 from './StepTasksListV2';
import { useEventPermissions, useTaskDetails, useTasksList } from '../../../hooks';
import TaskNewModal from '../../TaskModal/TaskNewModal';
import TaskDrawer from './TaskDrawer/TaskDrawer';
import { User } from '../../../types/user';
import TaskCompleteModal from '../../TaskModal/TaskCompleteModal';
import userTasksApi from '../../../api/userTasks.api';
import { DigitalSignatureModal } from '../../../components';
import { DigitalSignature, DigitalSignatureInput } from '../../../types/digitalSignature';
import issueStepsApi from '../../../api/issueSteps.api';
import { TaskPermissionsProvider, canCompleteTaskFn } from '../../../context';
import { isStepNotCompleted } from '../../../utils/eventUtils';
import { BASE_QUERY_KEY as TASKS_LIST_BASE_QUERY_KEY } from '../../../hooks/useTasksList';
import * as DisplayStrings from '../../../displayStrings';

type TasksStepProps = {
  step: EventStep;
  users: User[];
  refetch: () => void;
};

const getOrderByParam = (sort: Sorting.SortingContextValue | null) => {
  if (!sort?.column) {
    return undefined;
  }
  return sort.descending ? `-${sort.column}` : sort.column;
};

const TasksStepV2: React.FC<TasksStepProps> = ({ step, users, refetch }) => {
  const queryClient = useQueryClient();
  const stepId = step.id;
  const stepStatus = step.status;
  const stepNotCompleted = isStepNotCompleted(stepStatus);

  const { companyId, userId } = useCurrentUser();
  const { Provider: PaginationProvider, offset, limit } = Pagination.useLimitOffset();
  const [searchParams, setSearchParams] = useSearchParams();
  const { canCompleteTaskStep, canRevertStep, isEventOwner, canCreateTask } = useEventPermissions();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompleteStepModalOpen, setIsCompleteStepModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isRevertStepModalOpen, setIsRevertStepModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<UserTask | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { tasks } = useTasksList(stepId, companyId, 0, 1, {
    filter_by_issue_step_ids: String(stepId),
    status: ['open', 'closed_success', 'closed_fail'],
  });

  const anyTaskCreated = tasks && tasks?.items.length > 0;

  const handleTasksListApiCall = useCallback(
    ({ pageParam }: { pageParam: DataProvider.PageParams }) => {
      const orderBy = getOrderByParam(pageParam.sorting);
      return userTasksApi
        .getUserTasks(companyId, offset, limit, {
          filter_by_issue_step_ids: String(stepId),
          status: ['open', 'closed_success', 'closed_fail'],
          order_by: orderBy,
        })
        .then((data) => {
          return {
            itemCount: data?.total ?? 0,
            data: data?.items ?? [],
          };
        });
    },
    [companyId, offset, limit, stepId],
  );

  const taskIdFromUrl = searchParams.get('task_id');
  useTaskDetails(companyId, Number(taskIdFromUrl), !!taskIdFromUrl && !Number.isNaN(Number(taskIdFromUrl)), (data) => {
    if (data && data.related_to_id === stepId) {
      setSelectedTask(data);
      setIsDrawerOpen(true);
    }
  });

  const { tasks: openTaskList, isTasksListLoading: isOpenTasksListLoading } = useTasksList(stepId, companyId, 0, 15, {
    filter_by_issue_step_ids: String(stepId),
    status: ['open'],
  });

  const { tasks: doneTaskList, isTasksListLoading: isDoneTasksListLoading } = useTasksList(stepId, companyId, 0, 15, {
    filter_by_issue_step_ids: String(stepId),
    status: ['closed_success'],
  });

  const { tasks: closedTaskList, isTasksListLoading: isClosedTasksListLoading } = useTasksList(
    stepId,
    companyId,
    0,
    15,
    {
      filter_by_issue_step_ids: String(stepId),
      status: ['closed_fail'],
    },
  );

  const handleCompleteTask = async (data: TaskCompleteRequest) => {
    if (selectedTask) {
      await userTasksApi.close(companyId, selectedTask.id, data);
      queryClient.invalidateQueries({ queryKey: [TASKS_LIST_BASE_QUERY_KEY] });
      setSelectedTask({ ...selectedTask, status: data.resolution });
    }
  };

  const handleCompleteStep = async (payload: DigitalSignature) => {
    await issueStepsApi.signOff(companyId, step.issue_id, step.id, payload);
    refetch();
  };

  const handleRevertStep = async (payload: DigitalSignature) => {
    await issueStepsApi.revert(companyId, step.issue_id, step.id, payload);
    refetch();
  };

  const CreateTaskButton = () => (
    <QButton
      variant="ghost"
      leftIcon="PlusCircle"
      onClick={() => setIsModalOpen(true)}
      isDisabled={!canCreateTask}
      data-metrics="step-header-create-task-button"
    >
      {DisplayStrings.CreateTask}
    </QButton>
  );

  const CompleteStepButton = () => {
    const { data: tasks } = DataProvider.useDataProvider<UserTask>();
    const openTasks = tasks ? tasks.filter((task: UserTask) => task.status === 'open').length : 0;
    return (
      <QButton
        variant="ghost"
        leftIcon="CheckCircle"
        onClick={() => setIsCompleteStepModalOpen(true)}
        isDisabled={!canCompleteTaskStep || openTasks > 0}
        data-metrics="step-header-complete-step-button"
      >
        Complete step
      </QButton>
    );
  };

  const RevertStepButton = () => (
    <QButton
      variant="ghost"
      leftIcon="CornerUpLeft"
      onClick={() => setIsRevertStepModalOpen(true)}
      isDisabled={!canRevertStep}
      data-metrics="step-header-revert-step-button"
    >
      Revert
    </QButton>
  );

  const completeStepModalInput: DigitalSignatureInput = {
    headingText: 'Complete Step',
    subText: `Sign off to complete the "${step.label}" step`,
    submitButtonText: 'Complete step',
    successToastHeader: 'Step Completed',
    successToastDescription: `"${step.label}" step was marked as complete`,
  };

  const revertStepModalInput: DigitalSignatureInput = {
    headingText: 'Revert step completion?',
    subText: `Sign off to revert the completion of the "${step.label}" step`,
    submitButtonText: 'Revert step',
    successToastHeader: 'Step reverted',
    successToastDescription: `"${step.label}" step completion was reverted`,
  };

  const closeTaskDrawer = () => {
    const param = searchParams.get('task_id');
    if (param) {
      searchParams.delete('task_id');
      setSearchParams(searchParams);
    }
    setIsDrawerOpen(false);
  };

  const stepStillLoading = isOpenTasksListLoading || isDoneTasksListLoading || isClosedTasksListLoading;

  return (
    <StepContainer>
      <PaginationProvider>
        <Sorting.DefaultSortingProvider sortByKey="order_by">
          <DataProvider.Remote
            queryFn={handleTasksListApiCall}
            queryKey={[TASKS_LIST_BASE_QUERY_KEY, companyId, stepId, offset, limit]}
            queryParamKeys={{
              searchTerm: 'query',
              sortBy: 'order_by',
              pageSize: 'limit',
              pageIndex: 'offset',
            }}
          >
            <StepHeader
              title={step.label}
              status={stepStatus}
              createTaskButton={anyTaskCreated && <CreateTaskButton />}
              completeStepButton={<CompleteStepButton />}
              revertStepButton={<RevertStepButton />}
            />
            <StepBody isLoading={stepStillLoading}>
              <TaskStepMetadata
                stepId={stepId}
                openTasks={openTaskList}
                doneTasks={doneTaskList}
                closedTasks={closedTaskList}
              />
              {anyTaskCreated ? (
                <>
                  <StepTasksListV2
                    setIsCompleteModalOpen={setIsCompleteModalOpen}
                    setSelectedTask={setSelectedTask}
                    setIsDrawerOpen={setIsDrawerOpen}
                    isEventOwner={isEventOwner}
                  />
                  {selectedTask && (
                    <TaskPermissionsProvider
                      task={selectedTask}
                      userId={userId}
                      canCompleteTaskFn={canCompleteTaskFn}
                      isEventOwner={isEventOwner}
                    >
                      <TaskDrawer
                        key={`${selectedTask.id}-${selectedTask.updated_at}`}
                        task={selectedTask}
                        isOpen={isDrawerOpen}
                        onClose={closeTaskDrawer}
                        users={users}
                        setSelectedTask={setSelectedTask}
                        setIsCompleteModalOpen={setIsCompleteModalOpen}
                      />
                    </TaskPermissionsProvider>
                  )}
                </>
              ) : (
                <EmptyStep
                  text={
                    stepNotCompleted
                      ? 'Create and assign tasks and track their progress.'
                      : 'There are no tasks associated with this step.'
                  }
                >
                  {stepNotCompleted && <CreateTaskButton />}
                </EmptyStep>
              )}
            </StepBody>
            <TaskNewModal
              isOpen={isModalOpen}
              setIsOpen={setIsModalOpen}
              selectsData={{
                users,
              }}
              stepId={step.id}
            />
            <TaskCompleteModal
              isOpen={isCompleteModalOpen}
              setIsOpen={setIsCompleteModalOpen}
              onSave={handleCompleteTask}
              task={selectedTask!}
            />
            <DigitalSignatureModal
              isOpen={isCompleteStepModalOpen}
              setIsOpen={setIsCompleteStepModalOpen}
              onSave={handleCompleteStep}
              inputTexts={completeStepModalInput}
              isCommentRequired={false}
            />
            <DigitalSignatureModal
              isOpen={isRevertStepModalOpen}
              setIsOpen={setIsRevertStepModalOpen}
              onSave={handleRevertStep}
              inputTexts={revertStepModalInput}
              isCommentRequired={false}
            />
          </DataProvider.Remote>
        </Sorting.DefaultSortingProvider>
      </PaginationProvider>
    </StepContainer>
  );
};

export default TasksStepV2;
