import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { QButton, useCurrentUser } from '@qualio/ui-components';
import { EventStep, TaskCompleteRequest, UserTask, UserTasksListResponse } from '../../../types';
import StepContainer from './StepContainer';
import StepHeader from './StepHeader';
import StepBody from './StepBody';
import TaskStepMetadata from './TaskStepMetadata';
import EmptyStep from './EmptyStep';
import StepTasksList, { SortByType } from './StepTasksList';
import { useTasksList, useEventPermissions, useTaskDetails } from '../../../hooks';
import TaskNewModal from '../../TaskModal/TaskNewModal';
import TaskDrawer from './TaskDrawer/TaskDrawer';
import { User } from '../../../types/user';
import TaskCompleteModal from '../../TaskModal/TaskCompleteModal';
import userTasksApi, { UserTasksPageSize } from '../../../api/userTasks.api';
import { DigitalSignatureModal } from '../../../components';
import DigitalSignatureModalV2 from '../../DigitalSignatureModalV2/DigitalSignatureModal';
import { DigitalSignature, DigitalSignatureInput } from '../../../types/digitalSignature';
import issueStepsApi from '../../../api/issueSteps.api';
import { TaskPermissionsProvider, canCompleteTaskFn } from '../../../context';
import { isStepNotCompleted } from '../../../utils/eventUtils';
import { useFlags } from '../../../external/LaunchDarklyAdapter';

type TasksStepProps = {
  step: EventStep;
  users: User[];
  refetch: () => void;
  userTZ: string;
};

const TasksStep: React.FC<TasksStepProps> = ({ step, users, refetch, userTZ }) => {
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const { companyId, userId } = useCurrentUser();
  const { canCompleteTaskStep, canRevertStep, isEventOwner, canCreateTask } = useEventPermissions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCompleteStepModalOpen, setIsCompleteStepModalOpen] = useState(false);
  const [isCompleteModalOpen, setIsCompleteModalOpen] = useState(false);
  const [isRevertStepModalOpen, setIsRevertStepModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState<UserTask | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [sortByField, setSortByField] = useState('');
  const [sortByColumn, setSortByColumn] = useState<SortByType>([{ id: 'created_at', desc: true }]);
  const qeOnepassTesting = useFlags('qeOnepassTesting');

  const stepId = step.id;
  const SignatureModal = qeOnepassTesting ? DigitalSignatureModalV2 : DigitalSignatureModal;

  const { tasks, isTasksListLoading, isTasksListFetching, refetchTasks } = useTasksList(
    stepId,
    companyId,
    offset,
    UserTasksPageSize,
    {
      filter_by_issue_step_ids: String(stepId),
      status: ['open', 'closed_success', 'closed_fail'],
      order_by: sortByField,
    },
  );

  const paginationCallback = (pageIndex: number, pageSize: number) => {
    setOffset(pageIndex * UserTasksPageSize);
    setPageNumber(pageIndex);
  };

  const sortByCallback = (sortBy: SortByType) => {
    if (sortBy[0] !== undefined) {
      const sortParam = sortBy[0].desc ? `-${sortBy[0].id}` : sortBy[0].id;
      setSortByField(sortParam);
      setSortByColumn(sortBy);
    }
  };

  useEffect(() => {
    refetchTasks();
  }, [offset, refetchTasks]);

  useEffect(() => {
    if (tasks) {
      const count = Math.ceil(tasks.total / UserTasksPageSize);
      setPageCount(count);
    }
  }, [tasks]);

  const taskIdFromUrl = searchParams.get('task_id');
  useTaskDetails(companyId, Number(taskIdFromUrl), !!taskIdFromUrl && !isNaN(Number(taskIdFromUrl)), (data) => {
    if (data && data.related_to_id === stepId) {
      setSelectedTask(data);
      setIsDrawerOpen(true);
    }
  });

  const { tasks: openTaskList, isTasksListLoading: isOpenTasksListLoading } = useTasksList(stepId, companyId, 0, 15, {
    filter_by_issue_step_ids: String(stepId),
    status: ['open'],
  });

  const { tasks: doneTaskList, isTasksListLoading: isDoneTasksListLoading } = useTasksList(stepId, companyId, 0, 15, {
    filter_by_issue_step_ids: String(stepId),
    status: ['closed_success'],
  });

  const { tasks: closedTaskList, isTasksListLoading: isClosedTasksListLoading } = useTasksList(
    stepId,
    companyId,
    0,
    15,
    {
      filter_by_issue_step_ids: String(stepId),
      status: ['closed_fail'],
    },
  );

  const handleCompleteTask = async (data: TaskCompleteRequest) => {
    if (selectedTask) {
      await userTasksApi.close(companyId, selectedTask.id, data);
      queryClient.invalidateQueries({ queryKey: ['userTasksList'] });
      const result = await refetchTasks();
      const updatedTasks = result.data as UserTasksListResponse;
      const refetchedTask = updatedTasks?.items.find((task) => task.id === selectedTask.id);
      setSelectedTask(refetchedTask!);
    }
  };

  const handleCompleteStep = async (payload: DigitalSignature) => {
    await issueStepsApi.signOff(companyId, step.issue_id, step.id, payload);
    refetch();
  };

  const handleRevertStep = async (payload: DigitalSignature) => {
    await issueStepsApi.revert(companyId, step.issue_id, step.id, payload);
    refetch();
  };

  const CreateTaskButton = () => (
    <QButton
      variant="ghost"
      leftIcon="PlusCircle"
      onClick={() => setIsModalOpen(true)}
      isDisabled={!canCreateTask}
      data-metrics="step-header-create-task-button"
    >
      Create task
    </QButton>
  );

  const openTasks = tasks ? tasks?.items.filter((task: UserTask) => task.status === 'open').length : 0;
  const CompleteStepButton = () => (
    <QButton
      variant="ghost"
      leftIcon="CheckCircle"
      onClick={() => setIsCompleteStepModalOpen(true)}
      isDisabled={!canCompleteTaskStep || openTasks > 0}
      data-metrics="step-header-complete-step-button"
    >
      Complete step
    </QButton>
  );

  const RevertStepButton = () => (
    <QButton
      variant="ghost"
      leftIcon="CornerUpLeft"
      onClick={() => setIsRevertStepModalOpen(true)}
      isDisabled={!canRevertStep}
      data-metrics="step-header-revert-step-button"
    >
      Revert
    </QButton>
  );

  const completeStepModalInput: DigitalSignatureInput = {
    headingText: 'Complete Step',
    subText: `Sign off to complete the "${step.label}" step`,
    submitButtonText: 'Complete step',
    successToastHeader: 'Step Completed',
    successToastDescription: `"${step.label}" step was marked as complete`,
  };

  const revertStepModalInput: DigitalSignatureInput = {
    headingText: 'Revert step completion?',
    subText: `Sign off to revert the completion of the "${step.label}" step`,
    submitButtonText: 'Revert step',
    successToastHeader: 'Step reverted',
    successToastDescription: `"${step.label}" step completion was reverted`,
  };

  const closeTaskDrawer = () => {
    const param = searchParams.get('task_id');
    if (param) {
      searchParams.delete('task_id');
      setSearchParams(searchParams);
    }
    setIsDrawerOpen(false);
  };

  const stepStatus = step.status;
  const stepNotCompleted = isStepNotCompleted(stepStatus);
  const tasksCreated = tasks && tasks?.items.length > 0;
  const stepStillLoading =
    isTasksListLoading || isOpenTasksListLoading || isDoneTasksListLoading || isClosedTasksListLoading;

  return (
    <StepContainer>
      <StepHeader
        title={step.label}
        status={stepStatus}
        createTaskButton={tasksCreated && <CreateTaskButton />}
        completeStepButton={<CompleteStepButton />}
        revertStepButton={<RevertStepButton />}
      />
      <StepBody isLoading={stepStillLoading}>
        <TaskStepMetadata
          stepId={stepId}
          openTasks={openTaskList}
          doneTasks={doneTaskList}
          closedTasks={closedTaskList}
        />
        {tasks && tasksCreated ? (
          <>
            <StepTasksList
              tasks={tasks.items}
              isLoading={isTasksListLoading || isTasksListFetching}
              setIsCompleteModalOpen={setIsCompleteModalOpen}
              setSelectedTask={setSelectedTask}
              setIsDrawerOpen={setIsDrawerOpen}
              userTZ={userTZ}
              paginationCallback={paginationCallback}
              sortByCallback={sortByCallback}
              pageCount={pageCount}
              initialPageIndex={pageNumber}
              initialPageSize={UserTasksPageSize}
              isEventOwner={isEventOwner}
              sortByColumn={sortByColumn}
            />
            {selectedTask && (
              <TaskPermissionsProvider
                task={selectedTask}
                userId={userId}
                canCompleteTaskFn={canCompleteTaskFn}
                isEventOwner={isEventOwner}
              >
                <TaskDrawer
                  key={`${selectedTask.id}-${selectedTask.updated_at}`}
                  task={selectedTask}
                  isOpen={isDrawerOpen}
                  onClose={closeTaskDrawer}
                  users={users}
                  setSelectedTask={setSelectedTask}
                  setIsCompleteModalOpen={setIsCompleteModalOpen}
                />
              </TaskPermissionsProvider>
            )}
          </>
        ) : (
          <EmptyStep
            text={
              stepNotCompleted
                ? 'Create and assign tasks and track their progress.'
                : 'There are no tasks associated with this step.'
            }
          >
            {stepNotCompleted && <CreateTaskButton />}
          </EmptyStep>
        )}
      </StepBody>
      <TaskNewModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectsData={{
          users,
        }}
        stepId={step.id}
      />
      <TaskCompleteModal
        isOpen={isCompleteModalOpen}
        setIsOpen={setIsCompleteModalOpen}
        onSave={handleCompleteTask}
        task={selectedTask!}
      />
      <SignatureModal
        isOpen={isCompleteStepModalOpen}
        setIsOpen={setIsCompleteStepModalOpen}
        onSave={handleCompleteStep}
        inputTexts={completeStepModalInput}
        isCommentRequired={false}
      />
      <SignatureModal
        isOpen={isRevertStepModalOpen}
        setIsOpen={setIsRevertStepModalOpen}
        onSave={handleRevertStep}
        inputTexts={revertStepModalInput}
        isCommentRequired={false}
      />
    </StepContainer>
  );
};

export default TasksStep;
