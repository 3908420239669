import { createQColumnHelper, CurrentUserContextType, useToastProvider } from '@qualio/ui-components';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { EventType } from '../../types';
import eventTypesApi from '../../api/eventTypes.api';
import templatesApi from '../../api/templates.api';
import { useMemo } from 'react';

import { convertStringToJsDate } from '../../utils/datetimeUtils';
import * as DisplayStrings from '../../displayStrings';

const BASE_QUERY_KEY = 'eventTypesList';

type CompanyId = CurrentUserContextType['companyId'];
type TemplateId = EventType['id'];

export const useGetTemplates = (companyId: CompanyId) => {
  return useQuery({
    queryKey: [BASE_QUERY_KEY, companyId],
    queryFn: () => eventTypesApi.getAllEventTypes(companyId, true),
  });
};

export const useArchiveTemplate = (companyId: CompanyId) => {
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  return useMutation({
    mutationFn: (templateId: TemplateId) => templatesApi.archiveTemplate(companyId, templateId),
    onSuccess: () => {
      queryClient.invalidateQueries([BASE_QUERY_KEY, companyId]);

      showToast({
        title: 'Template archived',
        description: 'Template has been archived',
        status: 'success',
      });
    },
    onError: () => {
      showToast({
        title: 'Template archiving failed',
        description: 'Error occurred while archiving template',
        status: 'error',
      });
    },
  });
};

export const useRestoreTemplate = (companyId: CompanyId) => {
  const queryClient = useQueryClient();
  const { showToast } = useToastProvider();

  return useMutation({
    mutationFn: (templateId: TemplateId) => templatesApi.restoreTemplate(companyId, templateId),
    onSuccess: () => {
      queryClient.invalidateQueries([BASE_QUERY_KEY, companyId]);

      showToast({
        title: 'Template restored',
        description: 'Template has been restored',
        status: 'success',
      });
    },
    onError: () => {
      showToast({
        title: 'Template restoring failed',
        description: 'Error occurred while restoring template',
        status: 'error',
      });
    },
  });
};

const columnHelper = createQColumnHelper<EventType>();

const templateLink = (template: EventType, qualioFrontendRefreshEnabled: boolean): string => {
  const url = `/templates/${template.id}`;
  if (!qualioFrontendRefreshEnabled) {
    return '/quality-events' + url;
  }
  return url;
};

export const useColumnConfig = (qualioFrontendRefreshEnabled: boolean) => {
  return useMemo(() => {
    return {
      columnHelper: columnHelper,
      columnConfig: [
        columnHelper.text('prefix', { header: 'Prefix', minWidth: '5%', id: 'prefix' }),
        columnHelper.textLink('name', (template) => templateLink(template, qualioFrontendRefreshEnabled), {
          header: 'Title',
          id: 'title',
          isCrossMFE: false,
          isExternal: false,
        }),
        columnHelper.text((template) => `${template.defaultTimeLimit} days`, {
          header: 'Default time limit',
          minWidth: '10%',
          id: 'defaultTimeLimit',
        }),
        columnHelper.date((template) => (template.updatedAt ? convertStringToJsDate(template.updatedAt) : undefined), {
          header: 'Last modified',
          minWidth: '10%',
          id: 'lastModified',
        }),
        columnHelper.status(
          (template) => (template.active ? DisplayStrings.TemplateEffective : DisplayStrings.TemplateArchived),
          {
            header: 'Status',
            id: 'status',
            statuses: {
              [DisplayStrings.TemplateEffective]: 'green',
              [DisplayStrings.TemplateArchived]: 'red',
            },
            minWidth: '10%',
          },
        ),
      ],
    };
  }, [qualioFrontendRefreshEnabled]);
};
