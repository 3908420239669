import React, { useMemo, useState } from 'react';
import {
  useCurrentUser,
  QBox,
  QHeading,
  QButtonGroup,
  QButton,
  QSpinner,
  QStack,
  QSelect,
  QSelectItem,
  QFormControl,
  QDataTable,
  DataProvider,
  Pagination,
} from '@qualio/ui-components';
import { EventType } from '../../types';
import { useNavigate } from 'react-router-dom';
import TemplateRowActions from './TemplateRowActions';
import { useColumnConfig, useGetTemplates } from './hooks';
import { useDocTitle } from '../../hooks';
import { useFlags } from '../../external/LaunchDarklyAdapter';
import * as DisplayStrings from '../../displayStrings';

type TemplateStatusValues = 'active' | 'archived' | 'all';
type TemplateStatusLabels = typeof DisplayStrings.TemplateEffective | typeof DisplayStrings.TemplateArchived | 'All';
type StatusFilterOption = QSelectItem<TemplateStatusLabels, TemplateStatusValues>;

// Used by status filter dropdown
const statusSelectOptions: ReadonlyArray<StatusFilterOption> = [
  { value: 'active', label: DisplayStrings.TemplateEffective },
  { value: 'archived', label: DisplayStrings.TemplateArchived },
  { value: 'all', label: 'All' },
] as const;

const statusFilterFunctions = {
  active: (template: EventType) => template.active,
  archived: (template: EventType) => !template.active,
  all: (_template: EventType) => true,
} as const;

const QualityEventTemplates = () => {
  const { companyId } = useCurrentUser();
  const qualioFrontendRefreshEnabled = useFlags('qualioFrontendRefresh');
  const navigate = useNavigate();
  const { data: templates, isLoading } = useGetTemplates(companyId);
  const [filter, setFilter] = useState<TemplateStatusValues>('active');
  const tableData = useMemo(() => {
    if (!templates) {
      return [];
    }

    const filterOnStatus = statusFilterFunctions[filter];

    return templates.filter(filterOnStatus);
  }, [templates, filter]);

  useDocTitle('Event templates - Qualio');

  const { columnHelper, columnConfig } = useColumnConfig(qualioFrontendRefreshEnabled);

  return (
    <QStack direction="column" spacing={6}>
      <QBox display="flex" flexDirection="row" justifyContent="space-between">
        <QHeading size="lg">Event templates</QHeading>
        <QButtonGroup>
          <QButton data-cy={'create-template'} variant="solid" onClick={() => navigate('/templates/new')}>
            Create template
          </QButton>
        </QButtonGroup>
      </QBox>
      <QFormControl data-cy={'event-templates-filter'} w="300px">
        <QSelect
          value={filter}
          options={statusSelectOptions}
          onChange={(changeItem) => changeItem && setFilter(changeItem.value)}
          isDisabled={false}
          isLoading={false}
          isSearchable={false}
          filterOption={null}
        />
      </QFormControl>
      {isLoading ? (
        <QBox w="100%" textAlign="center" p={5} mt={16}>
          <QSpinner />
        </QBox>
      ) : (
        <QBox data-cy={'event-templates-table'}>
          <Pagination.Auto itemCount={tableData.length} clientSide>
            <DataProvider.Fixed data={tableData}>
              <QDataTable
                enableSorting
                columns={columnConfig.concat([
                  columnHelper.menu({
                    items: <TemplateRowActions companyId={companyId} />,
                  }),
                ])}
              />
            </DataProvider.Fixed>
          </Pagination.Auto>
        </QBox>
      )}
    </QStack>
  );
};

export default QualityEventTemplates;
