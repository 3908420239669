import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import { QBadge, QBox, QFlex, QHeading, QLink, QTag, QText } from '@qualio/ui-components';

import { StatusTag } from '../../components';
import { IntegrationFields, QualityEventDetailsResponse } from '../../types';
import { EventTemplateFieldAttributeNames } from '../../types/eventField';
import { EventTag } from '../../types/eventTag';
import {
  RelatedDocumentQriDetails,
  RelatedEventQriDetails,
  RelatedRegistryItemQriDetails,
  SupplierQriDetails,
} from '../../types/relatedData';
import { Relation } from '../../types/relation';
import { useLocalisedFormatDateTime } from '../../utils/datetimeUtils';
import { isFieldVisible } from '../../utils/eventTemplateUtils';
import IntegrationVariablesDrawer from './components/IntegrationVariablesDrawer';
import TagsSection from './components/TagsSection';
import { EscalationDetails } from '../../types/escalationDetails';
import IconlessLink from './components/IconlessLink';
import { PropertySection } from './components/PropertySection';
import { PropertySectionText } from './components/PropertySectionText';
import { stripQualioHost } from '../../utils/UrlUtils';
import { useFlags } from '../../external/LaunchDarklyAdapter';

type Props = {
  event: QualityEventDetailsResponse;
  relations: Relation[] | undefined;
  relatedSuppliers: SupplierQriDetails[] | undefined;
  relatedDocuments: RelatedDocumentQriDetails[] | undefined;
  relatedEvents: RelatedEventQriDetails[] | undefined;
  tags: EventTag[] | undefined;
  relatedProduct?: RelatedRegistryItemQriDetails | null;
  relatedRootCause?: RelatedRegistryItemQriDetails | null;
};

const EventProperties: React.FC<Props> = ({
  event,
  relations,
  relatedSuppliers,
  relatedEvents,
  relatedDocuments,
  relatedProduct,
  relatedRootCause,
  tags,
}) => {
  const issue = event.issue;
  const [isIntegrationFieldsVisible, setIsIntegrationFieldsVisible] = useState(false);
  const isRegistryProductRootCauseEnabled = useFlags('registryProductRootCause');

  const product = useMemo(() => {
    if (isRegistryProductRootCauseEnabled) {
      return {
        name: relatedProduct?.name,
        link: relatedProduct?.link ?? '',
        dataCySuffix: 'registry',
      };
    } else {
      return {
        name: issue.product_name,
        link: `/events?status=open&product_id=${issue.product_id}`,
      };
    }
  }, [relatedProduct, issue, isRegistryProductRootCauseEnabled]);

  const rootCause = useMemo(() => {
    if (isRegistryProductRootCauseEnabled) {
      return {
        name: relatedRootCause?.name,
        link: relatedRootCause?.link ?? '',
        dataCySuffix: 'registry',
      };
    } else {
      return {
        name: issue.rootcause_name,
        link: `/events?status=open&rootcause_id=${issue.rootcause_id}`,
      };
    }
  }, [relatedRootCause, issue, isRegistryProductRootCauseEnabled]);

  let issueTags: EventTag[] = [];
  const formatDateTime = useLocalisedFormatDateTime();

  const visibleFields = useMemo(() => {
    const visibleFields = new Set<string>();
    for (const field in EventTemplateFieldAttributeNames.Values) {
      if (isFieldVisible(event?.issue?.template_fields, EventTemplateFieldAttributeNames.parse(field))) {
        visibleFields.add(field);
      }
    }
    return visibleFields;
  }, [event]);

  if (tags && issue.tags_ids) {
    const ids = issue.tags_ids;
    issueTags = tags.filter((tag) => ids.includes(Number(tag.id)));
  }

  const sourceField = (name: string, url: string) => {
    return (
      <QLink href={stripQualioHost(url)} isCrossMFE={false}>
        <QFlex alignItems="center">{name}</QFlex>
      </QLink>
    );
  };

  const createIntegrationVariablesSection = (fields: IntegrationFields) => {
    const variablesCount = Object.keys(fields).length;
    return (
      <>
        <PropertySection title="Integration variables">
          <button onClick={() => setIsIntegrationFieldsVisible(true)}>
            <QText color="blue.500">{variablesCount} variables</QText>
          </button>
        </PropertySection>
        <IntegrationVariablesDrawer
          fields={fields}
          isOpen={isIntegrationFieldsVisible}
          onClose={() => setIsIntegrationFieldsVisible(false)}
        />
      </>
    );
  };

  const createRelatedEntitiesBadges = (entities: RelatedEventQriDetails[] | RelatedDocumentQriDetails[]) => {
    if (!entities || entities.length === 0) {
      return <QText>-</QText>;
    }
    return (
      <QFlex flexWrap="wrap">
        {entities.map(({ code, title, link, domain }) => (
          <QBadge mr={2} mb={2} key={code} textTransform="none">
            <Link color="blue.500" data-metrics={`event-details-related-${domain}-link`} to={link} title={title}>
              <QText color="blue.500">{code}</QText>
            </Link>
          </QBadge>
        ))}
      </QFlex>
    );
  };

  const createEscalationLinks = (details: EscalationDetails[]) => {
    if (details.length === 0) {
      return <QText>-</QText>;
    }
    return (
      <QFlex flexWrap="wrap">
        {details.map(({ id, code, title }) => (
          <QBadge mr={2} mb={2} key={code} textTransform="none">
            <Link
              color="blue.500"
              data-metrics={`event-details-escalation-${id}-link`}
              to={`/events/${id}`}
              title={title}
            >
              <QText color="blue.500">{code}</QText>
            </Link>
          </QBadge>
        ))}
      </QFlex>
    );
  };

  const createSupplierLinks = (suppliers: SupplierQriDetails[]): JSX.Element => {
    if (!suppliers || suppliers.length === 0) {
      return <QText>-</QText>;
    }
    const links = suppliers.reduce(
      (result, supplier, index, array) => {
        result.push(
          // Disabling rule here as I'm not familiar enough with the use case to know if supplier.id is unique
          // eslint-disable-next-line react/no-array-index-key
          <QLink key={`${index}-${supplier.id}`} href={stripQualioHost(supplier.link)} isCrossMFE>
            {supplier.name}
          </QLink>,
        );
        if (index < array.length - 1) {
          result.push(', ');
        }
        return result;
      },
      [] as (JSX.Element | string)[],
    );
    return <QText>{links}</QText>;
  };

  const riskSection = useMemo(() => {
    const issueRisk = issue.risk ? <QTag>{issue.risk}</QTag> : <PropertySectionText>{'-'}</PropertySectionText>;
    return visibleFields.has(EventTemplateFieldAttributeNames.Enum.risk) ? (
      <PropertySection title="Risk">{issueRisk}</PropertySection>
    ) : null;
  }, [visibleFields, issue]);

  return (
    <QBox>
      <QBox marginTop={4} marginBottom={4}>
        <QHeading size={'sm'}>Properties</QHeading>
      </QBox>
      <PropertySection title="Status">
        <StatusTag status={issue.status} />
      </PropertySection>
      <PropertySection title="Due">
        <PropertySectionText>{formatDateTime(issue.due_at)}</PropertySectionText>
      </PropertySection>
      <PropertySection title="Last modified">
        <PropertySectionText>{formatDateTime(issue.updated_at)}</PropertySectionText>
      </PropertySection>
      <PropertySection title="Owner">
        <PropertySectionText>{issue.owner}</PropertySectionText>
      </PropertySection>
      <PropertySection title="Created">
        <PropertySectionText>{`${formatDateTime(issue.created_at)} by ${issue.creator}`}</PropertySectionText>
      </PropertySection>
      <PropertySection title="Related events">{createRelatedEntitiesBadges(relatedEvents ?? [])}</PropertySection>
      <PropertySection title="Related documents">{createRelatedEntitiesBadges(relatedDocuments ?? [])}</PropertySection>
      <PropertySection title="Source event">{createEscalationLinks(event.issue.escalated_from ?? [])}</PropertySection>
      <PropertySection title="Escalated to">{createEscalationLinks(event.issue.escalated_to ?? [])}</PropertySection>
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.supplier) ? (
        <PropertySection title="Related suppliers">{createSupplierLinks(relatedSuppliers ?? [])}</PropertySection>
      ) : null}
      {riskSection}
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.risk) ? (
        <PropertySection title="Severity">
          <PropertySectionText>{issue.severity ?? '-'}</PropertySectionText>
        </PropertySection>
      ) : null}
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.risk) ? (
        <PropertySection title="Probability">
          <PropertySectionText>{issue.probability ?? '-'}</PropertySectionText>
        </PropertySection>
      ) : null}
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.product) ? (
        <PropertySection title="Product" dataCySuffix={`${product.dataCySuffix ?? ''}`}>
          <IconlessLink name={product.name} url={product.link} />
        </PropertySection>
      ) : null}
      {visibleFields.has(EventTemplateFieldAttributeNames.Enum.root_cause) ? (
        <PropertySection title="Root cause" dataCySuffix={`${rootCause.dataCySuffix ?? ''}`}>
          <IconlessLink name={rootCause.name} url={rootCause.link} />
        </PropertySection>
      ) : null}
      {issue.qe_source ? (
        <PropertySection title="Source">{sourceField(issue.qe_source.name, issue.qe_source.url)}</PropertySection>
      ) : null}
      {issue.fields && createIntegrationVariablesSection(issue.fields)}
      <TagsSection tags={issueTags} />
    </QBox>
  );
};

export default EventProperties;
