import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useQueryClient, useQuery } from 'react-query';
import {
  QBadge,
  QCloseButton,
  QDrawer,
  QDrawerHeader,
  QDrawerFooter,
  QButton,
  QDivider,
  QText,
  QBox,
  QSpacer,
  QTextarea,
  QFormControl,
  QFlex,
  useCurrentUser,
  QIconButton,
  QAttachments,
  QAttachment,
} from '@qualio/ui-components';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import TaskStatusTag from '../../../TaskStatusTag/TaskStatusTag';
import { UserTask, AddUserTaskCommentPayload } from '../../../../types';
import TaskCommentsContainer from '../TaskCommentsContainer';
import userTasksApi from '../../../../api/userTasks.api';
import { useAddUserTaskComment, useTaskEdit, useTaskPermissions } from '../../../../hooks';
import TaskEditModal from '../../../TaskModal/TaskEditModal';
import { User } from '../../../../types';
import { convertStringToDateTime, useLocalisedFormatDateTime, DateTimeFormat } from '../../../../utils/datetimeUtils';
import { buildAttachmentsHTML } from '../../../../utils/attachmentUtils';
import { attachmentUploadInfoProvider } from '../../../../api/attachments.api';
import { DetailRow } from './DetailRow';
import { DetailRowText } from './DetailRowText';
import { BASE_QUERY_KEY as TASKS_LIST_BASE_QUERY_KEY } from '../../../../hooks/useTasksList';

type Props = {
  task: UserTask;
  isOpen: boolean;
  onClose: () => void;
  users: User[];
  setSelectedTask: (userTask: UserTask) => void;
  setIsCompleteModalOpen: (status: boolean) => void;
};

const TaskDrawer: React.FC<Props> = ({ task, isOpen, onClose, users, setSelectedTask, setIsCompleteModalOpen }) => {
  const queryClient = useQueryClient();
  const { companyId } = useCurrentUser();
  const { canCompleteTask, canAddCommentToTask, canEditTask } = useTaskPermissions();
  const { data: userTaskComments, refetch } = useQuery(['userTaskComments', task.id], () =>
    userTasksApi.getComments(companyId, task.id),
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [attachments, setAttachments] = useState<Array<QAttachment>>([]);
  const attachmentsUrls = useRef('');
  const [uploadKey, setUploadKey] = useState(0);
  const formatDateTime = useLocalisedFormatDateTime();

  const getDueDate = (createdAt: number, timeLimitInDays: number) => {
    const dueDate = createdAt + timeLimitInDays * 24 * 60 * 60;
    return formatDateTime(dueDate, DateTimeFormat.TASK);
  };

  const formMethods = useForm<AddUserTaskCommentPayload>({
    mode: 'onSubmit',
    resolver: zodResolver(AddUserTaskCommentPayload),
  });

  const { handleSubmit, control, reset } = formMethods;

  const { addUserTaskCommentMutate, isAddUserTaskCommentLoading } = useAddUserTaskComment(task.id, () => {
    reset({ comment: '' });
    setAttachments([]);
    setUploadKey(uploadKey + 1);
    refetch().catch((e) => console.error(e));
  });

  useEffect(() => {
    attachmentsUrls.current = buildAttachmentsHTML(attachments);
  }, [attachmentsUrls, attachments]);

  const onSubmit = useCallback(
    async (payload: AddUserTaskCommentPayload) => {
      const updatedComment = payload['comment'] + attachmentsUrls.current;
      if (!updatedComment.trim()) {
        return;
      }

      const updatedData = { ...payload, ...{ comment: updatedComment } };
      return addUserTaskCommentMutate(updatedData);
    },
    [addUserTaskCommentMutate],
  );

  const { editTaskMutate } = useTaskEdit(task.id, (updatedTask) => {
    queryClient.invalidateQueries({ queryKey: [TASKS_LIST_BASE_QUERY_KEY] });
    if (updatedTask) {
      // Need to convert to userTask here since update route returns a Task object type
      const updatedUserTask = UserTask.parse({
        ...updatedTask,
        id: Number(updatedTask.id),
        owner: updatedTask.owner_full_name,
        creator: updatedTask.creator_full_name,
        created_at: convertStringToDateTime(updatedTask.created_at).toSeconds(),
        updated_at: convertStringToDateTime(updatedTask.updated_at).toSeconds(),
        closed_at: updatedTask.closed_at ? convertStringToDateTime(updatedTask.closed_at).toSeconds() : null,
        related_to_id: updatedTask.issue_step_id,
        related_to_url: task.related_to_url,
      });
      setSelectedTask(updatedUserTask);
    }
  });

  const [isProcessingFile, setIsProcessingFile] = useState(false);

  return (
    <QBox data-cy="task-drawer">
      <QDrawer onClose={() => onClose()} size="md" isOpen={isOpen}>
        <QBadge mt={4} mb={4} aria-label={'task-' + task.id}>
          TASK
        </QBadge>

        <QDrawerHeader>
          <QBox display={'flex'} w="md">
            <QText w="50%" textAlign={'justify'} noOfLines={3}>
              {task.title}
            </QText>
            <QBox display={'flex'} w="50%" alignSelf={'flex-start'} justifyContent={'space-between'}>
              {task.status === 'open' ? (
                <QFlex w="80%">
                  <QSpacer />{' '}
                  <QIconButton
                    onClick={() => setIsModalOpen(true)}
                    size="sm"
                    iconName="Edit3"
                    aria-label="edit-task"
                    data-metrics="edit-task-button"
                    isDisabled={!canEditTask}
                  />
                  <QSpacer />
                  <QButton
                    onClick={() => setIsCompleteModalOpen(true)}
                    variant="outline"
                    size="sm"
                    leftIcon="Check"
                    aria-label="complete-task"
                    data-metrics="complete-task-button"
                    isDisabled={!canCompleteTask}
                    data-cy="complete-task-button"
                  >
                    Complete
                  </QButton>
                </QFlex>
              ) : (
                <QSpacer />
              )}
              <QCloseButton onClick={() => onClose()} />
            </QBox>
          </QBox>
        </QDrawerHeader>
        <QDivider borderColor={'gray.500'} />
        <QBox mt={4}>
          <DetailRow title="Status">
            <QBox>
              <TaskStatusTag status={task.status} />
            </QBox>
          </DetailRow>
          <DetailRow title="Due">
            <DetailRowText>{getDueDate(task.created_at, task.time_limit)}</DetailRowText>
          </DetailRow>
          <DetailRow title="Last modified">
            <DetailRowText>
              {task.updated_at ? formatDateTime(task.updated_at, DateTimeFormat.TASK) : ' - '}
            </DetailRowText>
          </DetailRow>
          <DetailRow title="Created">
            <DetailRowText>
              {formatDateTime(task.created_at, DateTimeFormat.TASK)} by {task.creator}
            </DetailRowText>
          </DetailRow>
          <DetailRow title="ID">
            <DetailRowText>#T{task.id}</DetailRowText>
          </DetailRow>
          <QBox>
            <QText weight="semibold">Description</QText>
            <QText color={'gray.500'} textAlign={'justify'}>
              {task.description}
            </QText>
          </QBox>

          <TaskCommentsContainer userTaskComments={userTaskComments?.items || []} />
        </QBox>
        <QDrawerFooter w="100%" backgroundColor="gray.50" p={[4, 6]} borderTopWidth={1} borderTopColor="gray.200">
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <QFormControl>
                <Controller
                  control={control}
                  name="comment"
                  render={({ field: { ref: _ref, ...field } }) => (
                    <QTextarea
                      {...field}
                      aria-label="task-comment"
                      placeholder="Write a comment..."
                      backgroundColor="white"
                      data-cy="add-task-comment"
                      isDisabled={!canAddCommentToTask || isProcessingFile}
                    />
                  )}
                />
              </QFormControl>
              <QBox data-cy="select-attachments">
                <QAttachments
                  key={`${task.id}-${uploadKey}`}
                  attachments={attachments}
                  uploadInfoProvider={attachmentUploadInfoProvider}
                  onAdd={(attachment: QAttachment) => {
                    setAttachments([...attachments, attachment]);
                    return Promise.resolve();
                  }}
                  onClick={() => {
                    return Promise.resolve();
                  }}
                  onRemove={(fileId: string) => {
                    setAttachments(attachments.filter((a) => a.id !== fileId));
                    return Promise.resolve();
                  }}
                  onRevert={(fileId: string) => {
                    setAttachments(attachments.filter((a) => a.id !== fileId));
                    return Promise.resolve();
                  }}
                  isBusy={setIsProcessingFile}
                  maxFiles={10}
                  maxFileSize="100MB"
                  maxTotalFileSize="1000MB"
                  disabled={!canAddCommentToTask || isProcessingFile}
                />
              </QBox>
              <QButton
                variant="outline"
                type="submit"
                isLoading={isAddUserTaskCommentLoading}
                data-metrics="add-task-comment-button"
                isDisabled={!canAddCommentToTask || isProcessingFile}
                data-cy="add-task-comment-button"
              >
                Comment
              </QButton>
            </form>
          </FormProvider>
        </QDrawerFooter>
      </QDrawer>
      <TaskEditModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        selectsData={{ users: users }}
        task={task}
        onSave={editTaskMutate}
      />
    </QBox>
  );
};

export default TaskDrawer;
