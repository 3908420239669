import { useQuery } from 'react-query';
import { useCurrentUser } from '@qualio/ui-components';
import registryApi from '../api/registry.api';
import { RegistryItemsResponseType } from '../types/registry';
import { useFlags } from '../external/LaunchDarklyAdapter';

const transformData = (data: RegistryItemsResponseType) => {
  return (
    data?.items.map((item) => ({
      id: item.registry_item_matrix_id,
      name: item.name,
      description: item.description ?? '',
      companyId: item.company_id.toString(),
    })) || []
  );
};
export const useRegistryItems = (categoryId: string, searchTerm?: string) => {
  const { companyId } = useCurrentUser();
  const isRegistryProductRootCauseEnabled = useFlags('registryProductRootCause');

  const query = searchTerm ?? '';

  const searchParams = {
    offset: 0,
    limit: 100,
    order_by: 'name',
    query: query,
    status: 'effective',
    category_id: categoryId,
  };
  return useQuery({
    queryKey: ['registry-items', companyId, categoryId, searchParams],
    queryFn: () => registryApi.getRegistryItemsByCategory(companyId, searchParams),
    enabled: !!companyId && !!categoryId && isRegistryProductRootCauseEnabled,
    select: transformData,
  });
};
